import { get, orderBy } from 'lodash'

import {
  PageKey,
  PiggyEvent,
  TealiumComponent,
  TealiumEventAction,
  TealiumEventCategory,
  TealiumPageType,
} from 'enums/index'
import { getCommonTags, getPageTags } from 'client/helpers'
import {
  ADDITIONAL_COUNTRIES_OF_INITIAL_DEPOSIT_FIELD_NAME_PATH,
  COUNTRY_OF_INITIAL_DEPOSIT_FIELD_NAME_PATH,
  FIRST_DEPOSIT_SOURCE_FIELD_NAME_PATH,
  MARKETING_PREFERENCES_FIELD_NAME_PATH,
  REGULAR_PAYMENT_SOURCE_FIELD_NAME_PATH,
} from 'constants/formFieldNamePaths'
import {
  authorisedAdultMandate,
  interestRatesLabelText,
  personalBankingTermsLabelText,
  privacyNoticeLabelText,
  summaryBoxLabelText,
  ukFscsInfoLabelText,
} from 'client/pages/AccountInformationPage/ImportantInformationSection/dictionary'
import { T_TaggingSpecs } from 'types/index'

const pageKey = PageKey.AccountInformationPage

export const validFormSubmissionEvent = {
  trackingPageName: pageKey,
  component: TealiumComponent.Button,
  type: PiggyEvent.OnClick,
  submittingValidForm: true,
}

const getAllCommonTags = getCommonTags({
  ...getPageTags(pageKey),
  page_type: TealiumPageType.Application,
})

const accountInformationLinks = [
  {
    text: privacyNoticeLabelText,
    event_action: TealiumEventAction.Accordion,
    event_content: `privacy notice`,
    raw_datalayer: '3406v44',
  },
  {
    text: ukFscsInfoLabelText,
    event_action: TealiumEventAction.Accordion,
    event_content: `uk fscs`,
    raw_datalayer: '3406v45',
  },
  {
    text: personalBankingTermsLabelText,
    event_action: TealiumEventAction.Accordion,
    event_content: `personal banking t&c`,
    raw_datalayer: '3406v46',
  },
  {
    text: interestRatesLabelText,
    event_action: TealiumEventAction.Accordion,
    event_content: `interest rates`,
    raw_datalayer: '3406v48',
  },
  {
    text: summaryBoxLabelText,
    event_action: TealiumEventAction.OnSite,
    event_content: `summary box`,
    raw_datalayer: '3406v49',
  },
  {
    text: authorisedAdultMandate,
    event_action: TealiumEventAction.OnSite,
    event_content: `authorised adult mandate`,
    raw_datalayer: '3406v66',
  },
]

export const accountInformationPageTaggingSpecs: T_TaggingSpecs = [
  {
    description: `Fired when customer navigates to ${pageKey}.`,
    isViewEvent: true,
    eventIdentifier: {
      type: PiggyEvent.Mounted,
      trackingPageName: pageKey,
      component: TealiumComponent.WizardPage,
    },
    content: () => ({
      ...getAllCommonTags(),
      funnel_step: '4',
      raw_datalayer: '3406v38',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer selects the regular money source.`,
    eventIdentifier: {
      trackingPageName: pageKey,
      component: TealiumComponent.Select,
      name: REGULAR_PAYMENT_SOURCE_FIELD_NAME_PATH,
      type: PiggyEvent.OnChange,
    },
    content: ({ value }) => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Dropdown,
      event_content: `payments come from: ${value}`,
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v39',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer selects the country of initial deposit.`,
    eventIdentifier: {
      trackingPageName: pageKey,
      component: TealiumComponent.Select,
      name: COUNTRY_OF_INITIAL_DEPOSIT_FIELD_NAME_PATH,
      type: PiggyEvent.OnChange,
    },
    content: ({ value }) => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Dropdown,
      event_content: `initial deposit originate from: ${value}`,
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v41',
    }),
  },
  {
    description: `Fired on ${pageKey} when customer adds another country of initial deposit.`,
    eventIdentifier: {
      trackingPageName: pageKey,
      component: TealiumComponent.AddAnother,
      type: PiggyEvent.OnAdd,
      name: ADDITIONAL_COUNTRIES_OF_INITIAL_DEPOSIT_FIELD_NAME_PATH,
    },
    content: () => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Button,
      event_content: 'add another country or region',
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v42',
    }),
  },
  ...accountInformationLinks.map(({ text, event_action, event_content, raw_datalayer }) => ({
    description: `Fired on ${pageKey} when customer clicks on the ${text} link.`,
    eventIdentifier: {
      trackingPageName: pageKey,
      component: TealiumComponent.LinkTile,
      type: PiggyEvent.OnClick,
      text,
    },
    content: () => ({
      ...getAllCommonTags(),
      event_action,
      event_content,
      event_category: TealiumEventCategory.Content,
      raw_datalayer,
    }),
  })),
  {
    description: `Fired on ${pageKey} when customer submits the application.`,
    eventIdentifier: validFormSubmissionEvent,
    content: () => ({
      ...getAllCommonTags(),
      event_action: TealiumEventAction.Button,
      event_content: 'submit your application',
      event_category: TealiumEventCategory.Content,
      raw_datalayer: '3406v50',
      application_event: 'submitted',
    }),
  },
]

export const customAccountInformationPageTaggingSpecs: T_TaggingSpecs = [
  {
    description: `Fired on ${pageKey} upon application submission to tag first deposit source.`,
    eventIdentifier: validFormSubmissionEvent,
    content: ({ value }) => {
      const firstDepositSource = get(value, FIRST_DEPOSIT_SOURCE_FIELD_NAME_PATH, '')
      return {
        ...getAllCommonTags(),
        event_action: TealiumEventAction.Dropdown,
        event_content: `first deposit come from: ${firstDepositSource}`,
        event_category: TealiumEventCategory.Content,
        raw_datalayer: '3406v40',
      }
    },
  },
  {
    description: `Fired on ${pageKey} upon application submission to tag preferred way of contact.`,
    eventIdentifier: validFormSubmissionEvent,
    content: ({ value }) => {
      const marketingPreferences = get(value, MARKETING_PREFERENCES_FIELD_NAME_PATH, [])
      return {
        ...getAllCommonTags(),
        event_action: TealiumEventAction.Checkbox,
        event_content: `get in touch: ${orderBy(marketingPreferences).join(', ')}`,
        event_category: TealiumEventCategory.Content,
        raw_datalayer: '3406v43',
      }
    },
  },
]
