import { isFeatureEnabled } from 'client/utils/featureEnabled'
import { FeatureFlags, Product } from 'enums/index'

export const privacyNoticeLabelText = 'Privacy Notice'
export const ukFscsInfoLabelText = 'UK FSCS Information Sheet and Exclusions List'
export const personalBankingTermsLabelText = 'Personal Banking Terms and Conditions and Charges'
export const novLabelText =
  'Notice of Variation to Personal Banking Terms and Conditions and Charges – effective from 31 July 2023'
export const interestRatesLabelText = 'Interest Rates'
export const summaryBoxLabelText = 'Summary box'
export const authorisedAdultMandate = 'Authorised Adult Mandate'

export default {
  heading: 'Important information',
  paragraph1:
    'Before submitting the application, please take the time to read the following documents by clicking on the links below. We recommend that you download and print copies for your records.',
  links: [
    {
      label: privacyNoticeLabelText,
      isVisible: () => true,
      url: 'https://www.hsbc.co.uk/content/dam/hsbc/gb/pdf/privacy-notice2.pdf',
    },
    {
      label: ukFscsInfoLabelText,
      isVisible: () => true,
      url:
        'https://www.hsbc.co.uk/content/dam/hsbc/gb/pdf/legal-regulation/FSCS-information-sheet.pdf',
    },
    {
      label: personalBankingTermsLabelText,
      isVisible: () => true,
      url: 'https://www.hsbc.co.uk/content/dam/hsbc/gb/pdf/personal-banking-terms-conditions.pdf',
    },
    {
      label: novLabelText,
      isVisible: ({ features }) => isFeatureEnabled(features, FeatureFlags.NOV_ENABLED),
      url:
        'https://www.hsbc.co.uk/content/dam/hsbc/gb/pdf/notice-of-variation-terms-and-conditions-and-charges.pdf',
    },
    {
      label: interestRatesLabelText,
      isVisible: () => true,
      url: 'https://www.hsbc.co.uk/content/dam/hsbc/gb/pdf/savings/savings-interest-rates.pdf',
    },
    {
      label: summaryBoxLabelText,
      isVisible: () => true,
      url: 'https://www.hsbc.co.uk/savings/products/mysavings/',
    },
    {
      label: authorisedAdultMandate,
      isVisible: () => true,
      url: 'https://www.hsbc.co.uk/content/dam/hsbc/gb/pdf/authorised-adult-mandate.pdf',
    },
  ],
}
